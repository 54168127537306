export const openViewer = ({ path, realm, accessToken, viewerOrigin, iframeName } : { path?: string, realm: string, accessToken: string, viewerOrigin: string, iframeName?: string }) => {
    window.addEventListener('message', messageListener);


    const sendMsgToWindows = (win:Window) => {
        win.postMessage({accessToken, realm: 'kamotive'}, viewerOrigin);
    }

    let child: Window | null

    if (iframeName) {
        // @ts-ignore
        child = window.frames[iframeName];
    } else {
        child = window.open(path);
    }

    let maxGATime = 5000;

    const pollInterval = 50;


    const poll = setInterval(() => {
        maxGATime -= pollInterval;
        if(maxGATime <= 0) {
            clearInterval(poll);
            return;
        }

            if(child) {
                sendMsgToWindows(child)
            }
    }, pollInterval)


    function messageListener(event: MessageEvent) {
            if(event.origin !== viewerOrigin) {
                return;
            }
            clearInterval(poll);
            if(event.source) {
                sendMsgToWindows(event.source as Window);
            }

    }

}