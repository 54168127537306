import { useEffect, useState } from 'react';

export function useConfig() {
    const [UI_URL, setUI_URL] = useState<string>('');
    const [API_AUTH, setAPI_AUTH] = useState<string>('');

    const fetchConfig = async () => {
        fetch('/config/configDemoApp.json')
            .then((response) => response.json())
            .then((config) => {
                setUI_URL(config.KV_UI_URI || 'http://localhost:7069');
                setAPI_AUTH(config.KV_AUTH_API || 'http://localhost:8100');
            })
            .catch(() => {
                setUI_URL('http://localhost:7069');
                setAPI_AUTH('http://localhost:8100');
            });
    };
    useEffect(() => {
        fetchConfig();        
    }, []);
    return [UI_URL, API_AUTH];
}
