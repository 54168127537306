import * as React from "react"
import {
    ChakraProvider,
    Box, Button, FormControl, FormLabel, Input, Heading,
} from "@chakra-ui/react"
import {useEffect, useState} from "react";
import { openViewer } from "./openViewer";
import { useConfig } from "./useConfig";



interface ObjectType {
    '1': { objectType: string, objectId: string, path: string };
    '2': { objectType: string, objectId: string, path: string };
    '3': { objectType: string, objectId: string, path: string };
    '4': { objectType: string, objectId: string, path: string };
}

export const App = () => {
    const [isShow, setIsShow] = useState(false);
    const [id, toggleId] = useState<'1' | '2' | '3' | '4'>('1');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [realm, setRealm] = useState<string>('kamotive');

    const [accessToken, setAccessToken] = useState<string>('');

    const [objectType, setObjectType] = useState<string>('DOCUMENT');
    const [objectId, setObjectId] = useState<string>('object_id_1');
    const [path, setPath] = useState<string>('document:hibirit:Презентация по подшипникам КАМАЗ.pptx');


    const objects: ObjectType = React.useMemo(() => {
        return {
            '1': {objectType: 'DOCUMENT', objectId:'object_id_1', path:'document:hibirit:Презентация по подшипникам КАМАЗ.pptx' },
            '2': {objectType: 'DOCUMENT', objectId:'object_id_2', path:'document:hibirit:презентация KAMAZ ENG.pptx' },
            '3': {objectType: 'DOCUMENT', objectId:'object_id_3', path:'document:hibirit:пример чертежа JPEG.jpg' },
            '4': {objectType: 'DOCUMENT', objectId:'77192f1b-9b25-4704-ad45-7ad6742d062b', path:'DOCUMENT:77192f1b-9b25-4704-ad45-7ad6742d062b:PDF_Converter_Pro_Quick_Reference_Guide.RU.pdf' },

        }
    }, [])


    const [UI_URL, API_AUTH] = useConfig();

    const fullPathForViewer = React.useMemo(() => {
        return `${UI_URL}/viewer?objectType=${objectType}&objectId=${objectId}&path=${path}`;
    }, [UI_URL, objectType, objectId, path])


    const handleToggle = () => {
        if(id === '1') {
            toggleId('2');
        }
        if(id === '2') {
            toggleId('3');
        }

        if(id === '3') {
            toggleId('4');
        }

        if(id === '4') {
            toggleId('1');
        }
    }

    const generateTokens = React.useCallback(async () => {
        const response = await fetch(API_AUTH+'/api/v1/login', {
            method: 'POST',
            body: JSON.stringify({
                "username": username,
                "password": password,
                "realm": realm,
            }),
            headers: {
                'content-type': 'application/json'
            }
        })
        response.json().then( data => {
            setAccessToken(data.access_token);
        })
    }, [API_AUTH, username, password, realm]);

    const login = async () => {
        await generateTokens();
        alert('Вы вошли под пользователем ' + username)
        setUsername('');
        setPassword('');
    }

    useEffect(() => {
        setObjectType(objects[id].objectType);
        setObjectId(objects[id].objectId);
        setPath(objects[id].path);
    }, [id, objects]);

    return (
        <ChakraProvider>
            <Heading mb={4} m={30} ml={500}>Выбор пользователя</Heading>
            <Box fontSize="xl" m={30} ml={500} mr={500} width={200}>
                <FormControl>
                    <FormLabel>username</FormLabel>
                    <Input placeholder='username' value={username} onChange={(e) => setUsername(e.target.value)}/>
                    <FormLabel>password</FormLabel>
                    <Input placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <FormLabel>realm</FormLabel>
                    <Input placeholder='realm' value={realm} onChange={(e) => setRealm(e.target.value)}/>
                    <Button onClick={login} m={3} ml={12}>Войти</Button>
                </FormControl>
            </Box>
            <Heading mb={4} m={30} ml={500}>Выбор документа</Heading>
            <Box fontSize="xl" m={30} ml={500} mr={500} width={1200}>
                <Button mt={5} mb={5} onClick={handleToggle}>Демо-документ №{id}</Button>
                <FormControl>
                    <FormLabel>objectType</FormLabel>
                    <Input placeholder='objectType' value={objectType} onChange={(e) => setObjectType(e.target.value)}/>
                    <FormLabel>objectId</FormLabel>
                    <Input placeholder='objectId' value={objectId} onChange={(e) => setObjectId(e.target.value)}/>
                    <FormLabel>path</FormLabel>
                    <Input placeholder='path' value={path} onChange={(e) => setPath(e.target.value)}/>

                </FormControl>
            </Box>
            <Box textAlign="center" fontSize="xl" m={5}>
                <Button onClick={() => {
                    setIsShow(prevState => !prevState);
                }} fontSize="2xl" color="teal.500" m={5}>
                    { isShow ? 'Скрыть' : 'Запустить' } просмотр объекта: ({objectId})  в IFrame
                </Button>
                <Button fontSize="2xl" onClick={() => openViewer({path: fullPathForViewer, realm, accessToken, viewerOrigin: UI_URL })}>
                    Перейти в полноэкранный режим
                </Button>
                {isShow
                    &&
                    <Box m={'auto'} boxShadow='dark-lg' width={1500} height={800} border='3px' borderColor='gray.200'>
                        <iframe title={'kamotive viewer'} name={'iframe'} height={800} width={1500} src={fullPathForViewer}
                                onLoad={() => {openViewer({realm, accessToken, viewerOrigin: UI_URL, iframeName: 'iframe'})}}
                        />
                    </Box>
                }
            </Box>
        </ChakraProvider>
    )
}
